import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/commons/Layout";
import styled from "styled-components";
import theme from "../constants/theme";
import Container from "../components/layout/Container";
import ResponsiveText from "../components/typography/ResponsiveText";
import UnorderedList from "../components/commons/UnorderedList";
import { Link } from 'gatsby-plugin-react-i18next';
import SectionHeroFullScreen from '../components/sections/SectionHeroFullScreen';

const CareersWrapper = styled.div`
  .jobs-container {
    background-color: ${theme.colorWhite};
    color: ${theme.colorBlack};
    min-height: 100vh;
    padding: 100px 0;

    h2 {
      margin-bottom: 60px;
    }

    .container {
      flex-wrap: wrap;
    }

    ul {
      width: 100%;

      li {
        font-family: ${theme.fontSyne};
        font-size: 26px;
        font-weight: 700;
        width: 100%;
        border-bottom: 1px solid ${theme.colorGray};

        @media (min-width: 1025px) {
          &:hover {
            color: ${theme.colorWhite};
            background-color: ${theme.colorBlack};

            span.job-team {
              color: ${theme.colorYellow};
            }
          }
        }

        a {
          display: block;
          padding-top: 29px;
          padding-bottom: 29px;

          span {
            &.job-title {
              margin-right: 12px;
              text-transform: uppercase;
            }

            &.job-team {
              vertical-align: middle;
              line-height: 39px;
              font-size: 16px;
              text-transform: uppercase;
              font-weight: 700;

              &:before,
              &:after {
                content: "/";
              }
            }

            &.contract-type,
            &.location {
              text-transform: capitalize;
              letter-spacing: 0px;
              font-weight: 400;
              font-family: ${theme.fontSyne};
              margin-right: 16px;
              font-size: 16px;

              &:before {
                content: "";
                background-color: ${theme.colorYellow};
                display: inline-block;
                width: 11px;
                height: 11px;
                margin-right: 8px;
              }
            }
          }
        }

        @media (max-width: 1024px) {
          line-height: 39px;
        }
      }
    }
  }

  .spontaneous-application-form-container {
    background-color: ${theme.colorBlack};
    color: ${theme.colorWhite};
    padding-top: 48px;

    .grid {
      height: 100%;
    }

    .job-heading {
      margin-top: 10%;
      max-width: 80%;

      @media (max-width: 1024px) {
        max-width: 100%;
      }

      & > * {
        margin-bottom: 20px;
      }
    }

    .form-wrapper {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 1024px) {
    .jobs-container {
      min-height: unset;
      padding: 100px 0;
    }
  }

  @media (max-width: 480px) {
    .jobs-container {
      ul {
        li {
          font-size: 28px;

          a {
            padding-top: 20px;
            padding-bottom: 20px;

            span {
              &.job-title,
              &.job-team {
                display: inline-block;
              }

              &.job-team {
                order: -1;
              }
            }
          }
        }
      }
    }
  }
`;

export default function Careers({ data }) {
  const {
    backend: { paginaJob, jobs, seoRules },
  } = data;

  const hubspotFormId = paginaJob.footerWithForm.hubspotForm;

  useEffect(function () {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "14542219",
          formId: hubspotFormId,
          target: "#form-spontaneous-application",
        });
      }
    });
  }, []);

  return (
    <Layout seo={seoRules && seoRules[0] && seoRules[0].seo}>
      <CareersWrapper>
         <SectionHeroFullScreen
          title={paginaJob.Hero.title}
          featuredImage={paginaJob.Hero.featuredImage}
          tags={undefined}
          isDark
        />
        <div className="container mx-auto mt-32 mb-32">
          <ResponsiveText type="medium">
            <p className=" mb-12">{paginaJob.Hero.bigText.anchor}</p>
          </ResponsiveText>
          <ResponsiveText type="normal">
            <p>{paginaJob.Hero.bigText.content}</p>
          </ResponsiveText>
        </div>
        <div>
          <UnorderedList
            title={paginaJob.UnorderedList.title}
            items={paginaJob.UnorderedList.UnorderedListElement}
          />
        </div>
        <div
          className="jobs-container"
          data-scroll-id="posizioniAperteSection"
          data-scroll-section
          data-scroll
        >
          <Container>
            <ResponsiveText type="extreme">
              <h2>Posizioni aperte</h2>
            </ResponsiveText>
          </Container>
          <ul>
            {jobs.map((job, index) => {
              const openExternalLink =
                job.externalLink && job.externalLink !== "";
              const [tipoContratto, luogoDiLavoro] =
                job.tipoContratto.split(", ");

              return (
                <li key={index}>
                  <Link
                    className="hover-card"
                    to={
                      openExternalLink
                        ? job.externalLink
                        : "/careers/" + job.slug
                    }
                    target={openExternalLink ? "_blank" : "_self"}
                    rel={openExternalLink ? "noreferrer" : undefined}
                  >
                    <Container marginBottom="0px">
                      <span className="job-title">{job.title}</span>
                      {job.reparto && <span className="job-team">{job.reparto}</span>}
                    </Container>
                    <Container marginBottom="0px">
                      {tipoContratto && <span className="contract-type">{tipoContratto}</span>}
                      {luogoDiLavoro && <span className="location">{luogoDiLavoro}</span>}
                    </Container>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className="spontaneous-application-form-container"
          data-scroll
          data-scroll-id="spontaneousApplicationSection"
          data-scroll-section
          style={{ marginTop: "40px" }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto">
            <div className="job-heading">
              <ResponsiveText type="medium">
                <h2>
                {paginaJob.footerWithForm.title}
                </h2>
              </ResponsiveText>
              <ResponsiveText type="small">
                {paginaJob.footerWithForm.subtitle}
              </ResponsiveText>
            </div>
            <div
              className="form-wrapper"
              id="form-spontaneous-application"
            ></div>
          </div>
        </div>
      </CareersWrapper>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    backend {
      jobs {
        externalLink
        hubspotForm
        reparto
        slug
        tipoContratto
        title
      }
      paginaJob {
        title
        Hero {
          title
          bigText {
            anchor
            content
          }
          featuredImage {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
        footerWithForm {
          hubspotForm
          subtitle
          title
        }
        UnorderedList {
          UnorderedListElement {
            description
            subtitle
            title
            id
          }
          title
        }
      }
      seoRules(where: { slug_eq: "/careers/" }) {
        slug
        seo {
          title
          description
          keywords
        }
      }
    }
  }
`;
